import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/bio"
import PageTitle from "../components/page-title"

function PageTemplate(props) {
  const {
    html,
    frontmatter: { title, description },
  } = props.data.markdownRemark

  return (
    <Layout location={props.location} title={title}>
      <SEO title={title} description={description} ogType="website" />
      <PageTitle>{title}</PageTitle>
      <div dangerouslySetInnerHTML={{ __html: html }} />
      <hr />
      <Bio />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
        social {
          twitter
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        description
      }
    }
  }
`

export default PageTemplate
